import './Posts.css';
import Cats from './StanAndAce.png'
import React from 'react';

const Posts = () => {
    return (
        <div>
            <div className="PlaceholderCard">
            <h1>
                😬 Nothing here yet...
            </h1>
            <h3>
                Soon I hope to write some small articles about computer science related topics, and will post them here.
            </h3>
            </div>
            <br/>
            <h3>
                For now heres a picture of my cats Stan and Ace.
            </h3>
            <img className="Cats" alt="Stan and Ace" src={Cats}></img>
        </div>
    )
};

export default Posts;