import './Work.css';
import React from 'react';

const Work = () => {
  return (
    <div>
        <div className="WorkCard" style={{border: 'none'}}>
              <h2>
                  2022
              </h2>
              <p>
                  I hope that this year I can get my first experience working as a software engineer! I'm hoping to work in a web related position, preferrably in the backend.
              </p>
        </div>
        <div className="WorkCard">
            <div className="Date">
                <h2>
                    2021
                </h2>
                <p>(2017-2021)</p>
            </div>
            <h3>
                Graduated from UCSC
            </h3>
            <p>Double major in Computer Science and Film/Digital Media</p>
        </div>
        <div className="WorkCard">
            <div className="Date">
                  <h2>
                      2019
                  </h2>
                  <p>June -  September</p>
            </div>
            <h3>
                Barista - Starbucks
            </h3>
            <p>Made and served bar drinks, ran the cash register, interacted with customers, and stocked the cafe.</p>
        </div>
        <div className="WorkCard">
              <div className="Date">
                  <h2>
                      2016
                  </h2>
                  <p>June -  September</p>
              </div>
            <h3>
                Lab Assistant - Lawrence Berkeley Labs
            </h3>
            <p>Operated lab equipment, used Scrum methodologies, and analyzed data in Microsoft Excel.</p>
        </div>
    </div>
  )
};

export default Work;
