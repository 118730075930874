import './Home.css';

const Home = () => {
    return (
        <div className="GreetingsCard">
            <h2 className="Greetings">
                👋 Hi, I'm Niland!
            </h2>
            <p className="Introduction">
                I'm a computer programer who lives in the Bay Area. Currently, I am looking for my first software engineering position and hope to be hired soon! In my spare time I enjoy running, reading, and building websites.
            </p>
            <br />
            <p>My Socials:</p>
            <div className="Icons">
                <a href="https://www.linkedin.com/in/niland-schumacher">
                    <img className="Icon" alt="link to Niland's linkedin" src="https://content.linkedin.com/content/dam/me/brand/en-us/brand-home/logos/In-Blue-Logo.png.original.png" />
                </a>
                <a href="https://github.com/nilandasd">
                    <img className="Icon" alt="link to Niland's github" src="https://cdn-icons-png.flaticon.com/512/25/25231.png" />
                </a>
                <a href="https://www.instagram.com/nilandude/">
                    <img className="Icon" alt="link to Niland's Handshake" src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/768px-Instagram_logo_2016.svg.png" />
                </a>
                <a href="mailto:naschuma@ucsc.edu">
                    <img className="Icon" alt="link to Niland's Handshake" src="https://cdn0.iconfinder.com/data/icons/material-circle-apps/512/icon-email-material-design-1024.png" />
                </a>
            </div>
        </div>
    );
}

export default Home;