import './App.css';
import Portrait from './Portrait.png'
import {useState} from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from './Home';
import Work from './Work';
import Projects from './Projects';
import Posts from './Posts';

const App = () => {
  const [location, setLocation] = useState("home");

  return (
    <div className="Container">
      <div className="Box">
        <BrowserRouter>
          <div className="Header">
            <img className="Portrait" alt="portrait of Niland" src={Portrait}/>
            <div>
            <Link to="/" style={{color: 'inherit', textDecoration: 'none'}}>
              <h1 onClick={() => setLocation('home')} style={{marginBottom: '10px'}}>
                Niland Schumacher
              </h1>
            </Link>

            <div className="Navigation">
              <Link to="work" style={{ color: 'inherit', textDecoration: 'none' }}>
                  <h2 className="NavItem" style={{
                    borderRadius: '5px', padding: '10px', color: `${location === 'work' ? '#246fff' : 'black'}`,
                    boxShadow: `${location === 'work' ? "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset" : 'none'}`
                  }}
                    onClick={() => setLocation('work')}
                  >
                  Work
                </h2>
              </Link>
              <Link to="projects" style={{ color: 'inherit', textDecoration: 'none' }}>
                  <h2 className="NavItem" style={{ borderRadius: '5px', padding: '10px', color: `${location === 'projects' ? '#246fff' : 'black'}`,
                    boxShadow: `${location === 'projects' ? "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset" : 'none'}` }}
                    onClick={() => setLocation('projects')}
                  >
                  Projects
                </h2>
              </Link>
              <Link to="posts" style={{ color: 'inherit', textDecoration: 'none' }}>
                  <h2 className="NavItem" style={{
                    borderRadius: '5px', padding: '10px', color: `${location === 'posts' ? '#246fff' : 'black'}`,
                    boxShadow: `${location === 'posts' ? "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset" : 'none'}`
                  }}
                    onClick={() => setLocation('posts')}
                  >
                  Posts
                </h2>
              </Link>
              </div>
            </div>
          </div>
          <div className="Content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/work" element={<Work />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/posts" element={<Posts />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
