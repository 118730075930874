import './Projects.css';
import React from 'react';

const Projects = () => {
    return (
        <div>
            <div className="ProjectCard">
                <h1>
                    Twitter Clone
                </h1>
                <h3>
                    A copy of Twitter(for educational purposes)
                </h3>
                <p>
                    I just started working on this project in February 2022. The goal of this project is to practice and experiment with using AWS.
                    Some of the AWS services I plan on using are the API Gateway, lmabda functions, dynamoDB, and Cloudwatch.
                </p>
                <br />
                <p>
                    <strong>Stack: React, Express, Node, DynamoDB, AWS</strong>
                </p>
            </div>
            <br/>
            <div className="ProjectCard">
                <h1>
                    Strikeboard.net
                </h1>
                <h3>
                    A website for tracking your tasks.
                </h3>
                <p>
                    I started working on this project in October of 2021, and released it to the internet in January 2022.
                    The main purpose of this project was simply to advance my understanding in working with fullstack applications. 
                </p>
                <br/>
                <p>
                    The technologies I especially enjoyed in this project, and that I hope to continue using, were React, Express, AWS, and Docker.
                    However, this project had some major flaws, which I would want to fix in the next project I work on.
                    1) Little to no testing =&gt; next time I want to implement testing from the start and use TDD!
                    2) No API validation/documentation =&gt; I hope to use Swagger for my next API
                </p>
                <br />
                <p>
                    <strong>Stack: React, Express, Node, MongoDB, AWS, Redis, Nginx</strong>
                </p>
                <br/>
                <p>

                    
                </p>
                <a href="https://strikeboard.net">https://strikeboard.net</a>
            </div>
        </div>
    )
};

export default Projects;